import React from 'react';
// component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faFile, faChartLine, faRightToBracket, faGears, faPenToSquare} from '@fortawesome/free-solid-svg-icons';

// ----------------------------------------------------------------------
const iconFA = (iconComponent) => <FontAwesomeIcon icon={iconComponent} style={{ width: '1em', height: '1em' }} />;
const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: iconFA(faChartLine),
  },
  {
    title: 'Dropshipzone login',
    path: '/dashboard/dszlogin',
    icon: iconFA(faRightToBracket),
  },
  {
    title: 'New Product Launch',
    path: '/dashboard/newproductlaunch',
    icon: iconFA(faRocket),
  },
  {
    title: 'Existing Product Manage',
    path: '/dashboard/existingproductmanage',
    icon: iconFA(faPenToSquare),
  },
  {
    title: 'Settings',
    path: '/dashboard/setting',
    icon: iconFA(faGears),
  },
  {
    title: 'Privacy Policy',
    path: '/privacypolicy',
    icon: iconFA(faFile),
  },
];

export default navConfig;
